<template>
  <div>
    <!-- Main Row -->
    <div class="row">
      <!-- Form -->
      <div class="col-md-12">
        <Card :title="data.name">
          <template v-slot:body>
            <div v-html="data.template"></div>
            <button
              @click="
                $router.push({ path: '/masters/lab-templates/edit/' + data.id })
              "
              v-if="manipulateBtn == true"
              class="btn mx-1 my-4 btn-success"
            >
              <i class="far fa-fw fa-edit"></i> Edit
            </button>
            <button @click="deleteData" v-if="manipulateBtn == true" class="btn mx-1 my-4 btn-danger">
              <i class="far fa-fw fa-trash-alt"></i> Hapus
            </button>
          </template>
        </Card>
      </div>
    </div>
  </div>
</template>

<script>
import Card from "@/view/content/Card.vue";
import module from "@/core/modules/CrudModule.js";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";

export default {
  data() {
    return {
      // Data
      data: {
        name: "",
      },
      // Other
      imgLoaded: false,
      manipulateBtn:false
    };
  },

  components: {
    Card,
  },

  methods: {
    onImageLoad() {
      this.imgLoaded = true;
    },

    async deleteData() {
      let result = await module.delete(
        "lab-templates/" + this.$route.params.id
      );
      // If Deleted
      if (result) {
        // Redirect To List
        this.$router.push("/masters/lab-templates/list");
      }
    },

    async get() {
      this.data = await module.get("lab-templates/" + this.$route.params.id);
      // If Data Not Found
      if (this.data == null) {
        // Redirect To List
        this.$router.push("/masters/lab-templates/list");
      }
    },

    // access management
    async setActiveMenu(){

     let access_right_user = window.localStorage.getItem("access_right_display")
     let access_right = JSON.parse(access_right_user)
    
    let a
    for(a = 0; a < access_right.length; a++){
      
      
      if(access_right[a] == "1102"){
        this.manipulateBtn = true
      }
      
    }
    },
  },

  mounted() {
    // BreadCrumb
    this.$store.dispatch(SET_BREADCRUMB, [
      {
        title: "Daftar Template Laporan Lab",
        route: "/masters/lab-templates/list",
      },
      { title: "Detail" },
    ]);
    // Get Data
    this.get();
    this.setActiveMenu()
  },
};
</script>